.Background {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.Header {
    background-color: #282c34;
    display: flex;
    justify-content: center;
}

.Header-Button {
    background-color: #282c34;
    padding: 5px;
}

.slide-in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform-origin: bottom;
    background: #aaaaaa;
}


.slide-out {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform-origin: top;
    background: #aaaaaa;
}
